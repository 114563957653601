
import { computed, defineComponent, ref, shallowRef, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import * as _ from 'lodash'
export default defineComponent({
    props: {
        focused: Boolean,
        value: { type: null, default: () => [] },
        items: { type: Array, default: () => [] },
        itemValue: { type: String, default: 'id' },
        itemName: { type: String, default: 'name' },
        returnObject: Boolean,
        multiple: Boolean,
        label: { type: String, default: 'Поиск...' },
        disabled: { type: Boolean, default: false },
        openOnClick: { type: Boolean, default: true },
        transition: { type: null, default: 'v-menu-transition' },
        showTooltip: { type: Boolean, default: false },
        menuProps: { type: Object },
    },
    setup(props, { emit }) {
        const V_SCROLL_ITEM_HEIGHT = 48
        const V_SCROLL_DEFAULT_HEIGHT = 288

        const filteredItems = computed(() =>
            props.items?.filter((v: any) =>
                searchQuery.value ? v[props.itemName].match(new RegExp(searchQuery.value, 'i')) : props.items
            )
        )
        const vScrollHeight = computed(() =>
            _.clamp(filteredItems.value.length * V_SCROLL_ITEM_HEIGHT, 0, V_SCROLL_DEFAULT_HEIGHT)
        )

        const selected = computed(() => {
              return props.multiple
                ? props.items?.filter((el: any) => props.value.includes(el[props.itemValue]))
                : props.items?.find((el: any) => el[props.itemValue] === props.value)
          }
        )

        const searchQuery = ref('')
        const menuOpened = ref(false)
        const selectAllIconColor = 'main'
        const update = (val: any) => {
            emit('input', val)
        }
        watch(
            menuOpened,
            val => {
                setTimeout(() => (searchQuery.value = ''), 200)
            },
            { immediate: true }
        )

        const selectedAll = computed(() => props.value.length === props.items?.length)

        const selectAllIcon = computed(() => {
            if (selectedAll.value) return 'mdi-checkbox-outline'
            if (props.value.length) return 'mdi-minus-box-outline'
            return 'mdi-checkbox-blank-outline'
        })
        const select = (item: any) => {
            if (item.disabled) return
            if (props.multiple) {
                const val = !props.value.includes(item[props.itemValue])
                if (val) {
                    update(props.value.concat(item[props.itemValue]))
                } else {
                    update(props.value.filter((el: any) => el !== item[props.itemValue]))
                }
            } else {
                update(item[props.itemValue])
            }
        }
        const openMenu = () => (menuOpened.value = true)

        const closeMenu = () => (menuOpened.value = false)

        const selectAll = () => {
            if (props.value.length) {
                update([])
            } else {
                update(props.items?.filter((el: any) => !el.disabled).map((el: any) => el[props.itemValue]))
            }
        }
        const activatorId = computed(() => getActivatorId())
        const getActivatorId = () => 'select-activator' + '-' + uuidv4().slice(0, 10)
        watch(selected, () => {
            emit('update-selected', selected.value)
        })
        return {
            V_SCROLL_ITEM_HEIGHT,
            vScrollHeight,
            update,
            searchQuery,
            menuOpened,
            select,
            selected,
            activatorId,
            selectAll,
            selectAllIcon,
            selectAllIconColor,
            filteredItems,
            openMenu,
            closeMenu,
        }
    },
})
