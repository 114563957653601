
import { PropType, computed, defineComponent, watch, ref, getCurrentInstance } from 'vue'
import * as _ from 'lodash'
import AdvancedMessageEditor from '../../../../ui/AdvancedMessageEditor.vue'
import useRouter from '../../../../../hooks/useRouter'
import { Connector } from '@root/src/types/main'
import { CONNECTOR_TYPES } from '@root/src/vars/general'

export default defineComponent({
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    components: { AdvancedMessageEditor},
    setup(props, { emit }) {
        const { route } = useRouter()
        const root = getCurrentInstance()!.proxy
        const yClientsConnector = computed<Connector>(() =>
            root.$store.getters
                .projectById(root.$router.currentRoute.params.id)
                .connectors.find((el: Connector) => el.connectorType === CONNECTOR_TYPES.yClients)
        )
        const trigger = computed(() => route.value.query.id)
        const activeTab = ref(0)
        const templateVarsList = computed(() => {
            const vars = ['service', 'service.withPrice', 'date', 'staff.name', 'date.withoutDateButWithTime', 'date.onlyDate']
            if (yClientsConnector.value) {
                vars.push('service.backSlash')
            }
            return vars
        })

        const tabs = [
            {
                tabName: 'через администратора',
                key: 'admin',
                show: true,
            },
            {
                tabName: 'онлайн запись',
                key: 'online',
                show: true,
            },
        ]
        watch(
            () => props.value.isOnlineOtherText,
            (v: boolean) => {
                if (!v) {
                    activeTab.value = 0
                }
            }
        )
        const showDifferentMessagesTabs = computed(() => {
            return ['afterRecord', 'afterRecordNew', 'afterRecordPrev'].includes(trigger.value as string)
        })
        const getTextKey = () => {
            const activeTabKey = tabs[activeTab.value].key
            const textKey = activeTabKey === 'admin' ? 'text' : 'textForOnline'
            return textKey
        }

        const getTemplateKey = () => {
            const activeTabKey = tabs[activeTab.value].key
            const templateKey = activeTabKey === 'admin' ? 'template' : 'templateForOnline'
            return templateKey
        }
        const getText = () => {
            const key = getTextKey()
            return props.value[key] ?? ''
        }
        const updateText = (val: string) => {
            const key = getTextKey()
            emit('input', Object.assign({}, props.value, { [key]: val }))
        }
        const getTemplate = () => {
            const key = getTemplateKey()
            return props.value[key] ?? ''
        }
        const updateTemplate = (val: string) => {
            const key = getTemplateKey()
            emit('input', Object.assign({}, props.value, { [key]: val }))
        }
        const updateBonusInfo = (val: string) => {
            emit('input', Object.assign({}, props.value, { bonusInfo: val }))
        }
        const textAdditionalVars = computed(() => {
            const messagevars = [
                { id: 'компания-наименование', name: 'компания-наименование' },
                { id: 'компания-адрес', name: 'компания-адрес' },
                { id: 'компания-телефон', name: 'компания-телефон' },

            ]
            if (yClientsConnector.value) {
                messagevars.push(
                    { id: 'ссылка-на-отзыв', name: 'ссылка-на-отзыв' },
                    { id: 'ссылка-на-информацию-записи', name: 'ссылка-на-информацию-записи' },
                    { id: 'общая-стоимость', name: 'общая-стоимость' },
                )
            }
            if (props.value.bonusInfo) {
                messagevars.push(
                    { id: 'начислено-бонусов', name: 'начислено-бонусов' },
                    { id: 'списано-бонусов', name: 'списано-бонусов' },
                    { id: 'баланс-бонусов', name: 'баланс-бонусов' }
                )
            }
            return messagevars
        })
        const textVarsList = ['recordData', 'client.name']

        return {
            updateText,
            textVarsList,
            textAdditionalVars,
            updateBonusInfo,
            trigger,
            isUndefined: _.isUndefined,
            showDifferentMessagesTabs,
            tabs,
            activeTab,
            getText,
            getTemplate,
            updateTemplate,
            templateVarsList,
        }
    },
})
